import React from 'react'
import { Helmet } from 'react-helmet'
import { get } from 'lodash'
import { useStaticQuery, graphql } from 'gatsby'

import { getMetaValues } from '../../lib/_helpers'

const query = graphql`
  query {
    homepage: wordpressPage(template: { eq: "template-homepage.php" }) {
      seo_meta {
        title
        description
      }
    }
  }
`

export default (props) => {
  const { title, description, image } = props
  const data = useStaticQuery(query)

  const defaultMeta = getSEOFromPage(get(data, 'homepage'))

  const v = getMetaValues(
    {
      title,
      description,
      image,
    },
    {
      ...defaultMeta,
    }
  )

  return (
    <React.Fragment>
      {v.title ? (
        <Helmet>
          <title>{v.title}</title>
          <meta property="og:title" content={v.title} />
        </Helmet>
      ) : null}
      {v.description ? (
        <Helmet>
          <meta name="description" content={v.description} />
          <meta property="og:description" content={v.description} />
        </Helmet>
      ) : null}
      {v.image ? (
        <Helmet>
          <meta property="og:image" content={v.image} />
          <meta name="twitter:card" content="summary_large_image" />
        </Helmet>
      ) : null}
    </React.Fragment>
  )
}

export const getSEOFromPage = (page) => {
  return {
    ...get(page, 'seo_meta'),
  }
}
