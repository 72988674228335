import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { connect } from 'react-redux'
import { CSSTransition } from 'react-transition-group'
import { get } from 'lodash'

import Footer from '../footer'
import PageIntro from '../page-intro'
import SEO from '../seo'

import { togglePageIntro } from '../../redux/actions'

class PageLayout extends React.Component {
  constructor(props) {
    super(props)

    this.mouseWheelHandler = this.mouseWheelHandler.bind(this)
    this.touchHandler = this.touchHandler.bind(this)

    this.state = {
      pageIntroSkip: !!props.location.hash,
      pageIntroDone: false,
      pageIntroFadeWait: true,
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    if (this.hasPageIntro()) {
      dispatch(togglePageIntro(true))
      this.bindScroll()
    } else {
      this.pageIntroDone()
      this.pageIntroFadeDone()
    }
  }

  componentWillUnmount() {
    this.unbindScroll()
    this.clearIntroTO()
    this.clearIntroFadeTO()
  }

  bindScroll() {
    window.addEventListener('wheel', this.mouseWheelHandler, { passive: false })
    this.el.addEventListener('touchstart', this.touchHandler, {
      passive: false,
    })
    this.el.addEventListener('touchmove', this.touchHandler, { passive: false })
  }

  unbindScroll() {
    window.removeEventListener('wheel', this.mouseWheelHandler)
    this.el.removeEventListener('touchstart', this.touchHandler)
    this.el.removeEventListener('touchmove', this.touchHandler)
  }

  clearIntroTO() {
    if (this.pageIntroTO) {
      clearTimeout(this.pageIntroTO)
    }
  }

  clearIntroFadeTO() {
    if (this.pageIntroFadeTO) {
      clearTimeout(this.pageIntroFadeTO)
    }
  }

  mouseWheelHandler(e) {
    const { deltaX, deltaY } = e

    e.preventDefault()

    if (deltaY > 5) {
      this.pageIntroDone()
    }
  }

  touchHandler(e) {
    e.preventDefault()
  }

  pageIntroDone() {
    this.clearIntroTO()
    this.unbindScroll()

    this.pageIntroFadeTO = setTimeout(() => {
      this.pageIntroFadeDone()
    }, 600)

    this.setState({
      pageIntroDone: true,
    })
  }

  pageIntroFadeDone() {
    const { dispatch } = this.props

    this.clearIntroFadeTO()

    this.setState({
      pageIntroFadeWait: false,
    })
    dispatch(togglePageIntro(false))
  }

  hasPageIntro() {
    const { pageIntroSkip } = this.state
    const { pageIntroTitle } = this.props

    return !!pageIntroTitle && !pageIntroSkip
  }

  render() {
    const { pageIntroDone, pageIntroFadeWait } = this.state
    const {
      children,
      isDark,
      screenSize,
      introAnimationDone,
      introAnimationOutPerc,
      pageIntroTitle,
      pageIntroImage,
      pageIntroImageMobile,
      pageIntroColour,
      pageIntroAlignment,
      location,
      showFooter,
      data,
      seo,
    } = this.props

    const hasPageIntro = this.hasPageIntro()

    return (
      <div
        ref={(el) => {
          this.el = el
        }}
        className={classnames('pageLayout', {
          'pageLayout--hidden':
            !introAnimationDone || introAnimationOutPerc < 1,
          'pageLayout--black': isDark,
        })}
        style={{
          minHeight: screenSize.height,
        }}
      >
        <SEO {...seo} />

        {React.Children.map(children, (_child, _i) => {
          return React.cloneElement(_child, { fadeWait: pageIntroFadeWait })
        })}

        {hasPageIntro ? (
          <CSSTransition
            in={!pageIntroDone}
            unmountOnExit={true}
            classNames="pageIntroFade-"
            timeout={{
              enter: 900,
              exit: 600,
            }}
          >
            <PageIntro
              title={pageIntroTitle}
              image={pageIntroImage}
              mobileImage={pageIntroImageMobile}
              colour={pageIntroColour}
              alignment={pageIntroAlignment}
              onClick={() => {
                this.pageIntroDone()
              }}
            />
          </CSSTransition>
        ) : null}

        {showFooter ? <Footer /> : null}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { ui } = state

  return {
    introAnimationDone: ui.introAnimationDone,
    introAnimationOutPerc: ui.introAnimationOutPerc,
    screenSize: ui.screenSize,
    pageDark: ui.pageDark,
  }
}

export default connect(mapStateToProps, null)(PageLayout)
