import React 		from 'react'
import classnames 	from 'classnames'
import renderHTML 	from 'react-render-html'

import ScrollInViewport 	from '../scroll-in-viewport'

import {
	formatTitle
} from '../../lib/_helpers'

class TextEl extends React.Component {
	constructor(props) {
		super(props)
	}

	renderTitle() {
		const {
			title,
			titleEl
		} = this.props

		if(!title){ return null }

		const _title = formatTitle(title)

		switch(titleEl){
			case 'h1':
				return <h1>{ _title }</h1>
			case 'h1Secondary':
				return <h1 className="p--h2">{ _title }</h1>
			case 'h3':
				return <h3 className="p--h3">{ _title }</h3>
			default:
				return <h2>{ _title }</h2>
		}
	}

	render() {
		const {
			className,
			title,
			body,
			bodyStyling,
			sansStyling,
			children,
			delayTypeFade
		} = this.props

		return <div className={ classnames('textBlock textBlock--center textBlock--headline textBlock--scrollEl', {
				'textBlock--headline-body': bodyStyling,
				'textBlock--headline-sans': sansStyling,
				'textBlock--delayFade': delayTypeFade
			}, className) }>
			{ this.renderTitle() }
			{ body ? renderHTML(body) : null }
			{ children ? children : null }
		</div>
	}
}

const FixedIsActive = (WrappedComponent) => {
    return class extends React.Component {
        constructor(props) {
            super(props)
        }

        render() {
            const {
				isActive,
				className
            } = this.props

            return <WrappedComponent {...this.props} {...this.state}
                className={ classnames({
                    'scroll--isActive': !!isActive
                }, className) } />
        }
    }
}

const FixedTextEl = FixedIsActive(TextEl)
const ScrollTextEl = ScrollInViewport(TextEl)

export {
	TextEl,
	FixedTextEl,
	ScrollTextEl
}