import React 				from 'react'
import ReactDOM         	from 'react-dom'
import classnames 			from 'classnames'
import { connect } 			from 'react-redux'
import requestFrame    		from 'request-frame'
import getNodeDimensions 	from 'get-node-dimensions'
import PS 					from 'publish-subscribe-js'
import {
	inverseLerp
} from 'canvas-sketch-util/math'
import {
	keys,
	isFinite
} from 'lodash'

import ScrollIndicator 		from '../scroll-indicator'

import breakpoints 	from '../../lib/constants/breakpoints.json'

import {
	setHeaderColour,
	transitionHeaderColour
} from '../../redux/actions'

import {
	RESIZE,
	SCROLL,
	PAGE_INIT
} from '../../lib/pubSubEvents'

import {
	bgColourIsDark,
	renderHTML
} from '../../lib/_helpers'
import {
	roundTo
} from '../../lib/mathHelpers'

class SectionWrapper extends React.Component {
	constructor(props) {
		super(props)

		this.checkScrollUpdate = this.checkScrollUpdate.bind(this)
		this.initScrollCheck = this.initScrollCheck.bind(this)
	}

	componentDidMount(){
		this.bindScroll()
		this.initScrollCheck()
	}

	componentDidUpdate(prevProps){
		const {
			headerHeight,
			dispatch,
			fixed,
			scrollPx,
			screenSize,
			indexIsActive
		} = this.props

		if(fixed && isFinite(scrollPx)){
			if(
				scrollPx !== prevProps.scrollPx
				|| screenSize.height !== prevProps.screenSize.height
			){
				this.updateScrollPx()
			}
		}

	}

	componentWillUnmount() {
		this.unbindScroll()
	}

	initScrollCheck(){
		const {
			fixed,
			scrollPx
		} = this.props

		this.bindScroll()

		if(fixed){
			this.updateScrollPx()
		} else {
			this.checkScrollUpdate()
		}
	}

	bindScroll(){
		this.unbindScroll()

		this.resizeKey = PS.subscribe(RESIZE, this.checkScrollUpdate)
		this.scrollKey = PS.subscribe(SCROLL, this.checkScrollUpdate)
		this.pageInitKey = PS.subscribe(PAGE_INIT, this.initScrollCheck)

		this.checkScrollUpdate()
	}

	unbindScroll(){
		if(this.resizeKey){ PS.unsubscribe(RESIZE, this.resizeKey) }
		if(this.scrollKey){ PS.unsubscribe(SCROLL, this.scrollKey) }
		if(this.pageInitKey){ PS.unsubscribe(PAGE_INIT, this.initScrollCheck) }

		this.resizeKey = null
		this.scrollKey = null
	}

	getHeaderColour(){
		const {
			bgColour,
			headerDark,
			headerBgColour
		} = this.props

		if(headerDark || (bgColour && bgColourIsDark(bgColour))){
			return headerBgColour && bgColour ? bgColour : 'dark'
		} else {
			return 'light'
		}
	}
	
	checkScrollUpdate(){
		const {
			ignoreScrollMonitor,
			fixed
		} = this.props

		if(!this.sectionEl || ignoreScrollMonitor || fixed){ return }

    	const wrapEl = ReactDOM.findDOMNode(this.sectionEl)
    	const wrapElDimensions = getNodeDimensions(wrapEl)

    	this.updateScroll(wrapElDimensions.top, wrapElDimensions.bottom)
    }

    updateScrollPx(){
    	const {
    		scrollPx,
    		screenSize
    	} = this.props

    	const _scrollPx = scrollPx * - 1
		this.updateScroll(_scrollPx, _scrollPx + screenSize.height)
    }

    updateScroll(top, bottom){
    	const {
    		ignoreScrollMonitor,
			headerHeight,
			headerColour,
			headerTransitioningColour,
			pageTransitioning,
			sectionEl,
			screenSize,
			dispatch
		} = this.props

		if(ignoreScrollMonitor){ return }

    	const newHeaderColour = this.getHeaderColour()
    	if(top > 0 && top <= headerHeight){
    		dispatch(transitionHeaderColour(newHeaderColour, roundTo(headerHeight - top, 100)))
    	} else if(top <= 0 && bottom > headerHeight){
    		if(
    			(headerColour !== newHeaderColour ||  headerTransitioningColour)
    			&& !pageTransitioning
    		){
    			dispatch(setHeaderColour(newHeaderColour))
    		}
    	}
    }

	render() {
		const {
			className,
			forceHeight,
			naturalHeight,
			ignoreHeader,
			screenSize,
			headerHeight,
			ignoreScrollMonitor,
			headerDark,
			bgColour,
			fixed,
			isLast,
			elID,
			showIndicator,
			children
		} = this.props

		const offset = ignoreHeader || (fixed && !isLast) ? 0 : headerHeight
		const height = naturalHeight && !fixed ? null : (
				isFinite(forceHeight) && !fixed ? forceHeight : screenSize.height - offset
			)
		const heightCssProp = fixed ? 'height': 'minHeight'

		const isDark = bgColour && bgColourIsDark(bgColour)

		return <section
			id={ elID ? elID : null }
			className={ classnames('section', {
				[`bg--${ bgColour }`]: bgColour,
				'txt--white': isDark
			}, className) }
			ref={ (el) => { this.sectionEl = el } }
			style={{
				[heightCssProp]: height
			}}>
			{ children }
			{ showIndicator ? <ScrollIndicator onDark={ isDark } /> : null }
		</section>
	}
}

const mapStateToProps = (state) => {
	const {
		ui
	} = state

    return {
    	headerHeight: ui.header.height,
    	headerColour: ui.header.colour,
    	headerTransitioningColour: ui.header.transitioningColour,
    	pageTransitioning: ui.pageTransitioning,
    	screenSize: ui.screenSize
    }
}

export default connect(mapStateToProps, null)(SectionWrapper)