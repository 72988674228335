import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'gatsby'

import breakpoints from '../../lib/constants/breakpoints.json'

import { openOverlay } from '../../redux/actions'

export default connect()((props) => {
  const { dispatch } = props

  return (
    <footer>
      <div className="nav-links">
        <ul>
          <li>
            <span
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()

                dispatch(openOverlay({ type: 'newsletter' }))
              }}
            >
              <span>Subscribe</span>
            </span>
          </li>
          <li>
            <Link to="/website-terms/">
              <span>Terms of use</span>
            </Link>
          </li>
          <li>
            <Link to="/info/#faqs">
              <span>FAQs</span>
            </Link>
          </li>
          <li>
            <Link to="/contact/">
              <span>Contact</span>
            </Link>
          </li>
        </ul>
      </div>
    </footer>
  )
})
