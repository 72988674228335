import React 			    from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import { connect }          from 'react-redux'
import {
    isFinite
} from 'lodash'
import {
    Link
} from 'gatsby'

class ScrollIndicator extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const {
            onDark
        } = this.props

        return <div
            className={ classnames('scrollIndicator', {
                'scrollIndicator--onDark': onDark
            }) } />
    }
}

export default ScrollIndicator