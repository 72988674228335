import React                from 'react'
import ReactDOM             from 'react-dom'
import classnames           from 'classnames'
import getNodeDimensions    from 'get-node-dimensions'
import { compose }          from 'redux'
import { connect }          from 'react-redux'
import PS                   from 'publish-subscribe-js'
import {
    isFinite
} from 'lodash'

import {
    RESIZE,
    SCROLL
} from '../../lib/pubSubEvents'

export const inView = (el, viewportSize, offsetTop = 1, offsetBottom = offsetTop, aboveOnly = false, checkBottom = false) => {
    const element = getNodeDimensions(el)
    const viewport = {
        width: viewportSize.width,
        height: viewportSize.height
    }

    const topOffset = viewport.height * offsetTop
    const bottomOffset = 0 + ((1 - offsetBottom) * viewport.height)

    return (
        (
            (!checkBottom && element.top < topOffset) ||
            (checkBottom && element.bottom < topOffset)
        )
        && (
            aboveOnly ||
            (
                (!checkBottom && element.bottom > bottomOffset) ||
                (checkBottom && element.top > bottomOffset)
            )
        )
    )
}

function ScrollInViewport(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props)

            this.prevScrollTop = 0
            this.checkScrollUpdate = this.checkScrollUpdate.bind(this)

            this.state = {
                scrollInView: false,
                scrollIsActive: false
            }
        }

        componentDidMount(){
            this.resizeKey = PS.subscribe(RESIZE, this.checkScrollUpdate)
            this.scrollKey = PS.subscribe(SCROLL, this.checkScrollUpdate)

            this.checkScrollUpdate()
        }

        componentWillUnmount() {
            if(this.resizeKey){ PS.unsubscribe(RESIZE, this.resizeKey) }
            if(this.scrollKey){ PS.unsubscribe(SCROLL, this.scrollKey) }
        }

        componentDidUpdate(prevProps){
            if(prevProps.wait !== this.props.wait){
                this.checkScrollUpdate()
            }
        }

        checkScrollUpdate() {
            const {
                checkBottomForActive
            } = this.props
            const {
                scrollInView,
                scrollIsActive
            } = this.state

            const scrollTop = window.pageYOffset || document.documentElement.scrollTop
            const scrollingUp = checkBottomForActive ? scrollTop < this.prevScrollTop : false

            if(this){
                const scrollEl = ReactDOM.findDOMNode(this)
                const scrollStates = this.checkIsInView(scrollEl, scrollingUp)
                if(
                    scrollInView !== scrollStates.scrollInView
                    || scrollIsActive !== scrollStates.scrollIsActive
                ){
                    this.setState(scrollStates)
                }
            }

            this.prevScrollTop = scrollTop
        }

        checkIsInView(el, scrollingUp = false) {
            const {
                screenSize,
                activeMult,
                wait
            } = this.props

            return {
                scrollInView: !wait && inView(el, screenSize),
                scrollIsActive: !wait && inView(el, screenSize, isFinite(activeMult) ? activeMult : 0.8, 1, true, scrollingUp)
            }
        }

        render() {
            const {
                scrollInView,
                scrollIsActive
            } = this.state
            const {
                className
            } = this.props

            return <WrappedComponent {...this.props} {...this.state}
                className={ classnames({
                    'scroll--inView': !!scrollInView,
                    'scroll--isActive': !!scrollIsActive
                }, className) } />
        }
    }
}


const mapStateToProps = (state) => {
    return {
        screenSize: state.ui.screenSize
    }
}

export default compose(
    connect(mapStateToProps, null),
    ScrollInViewport
)