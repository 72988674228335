import React from 'react'
import classnames from 'classnames'
import { Helmet } from 'react-helmet'
import { withContentRect } from 'react-measure'
import { connect } from 'react-redux'
import { isFunction } from 'lodash'

import ARYImage from '../ary-image'
import { ScrollTextEl } from '../section/text-el'

import breakpoints from '../../lib/constants/breakpoints.json'
import { bgColourIsDark } from '../../lib/_helpers'
import { ratioFromImage, shouldShowPortrait } from '../../lib/mathHelpers'

import { closeOverlay } from '../../redux/actions'

const PageIntroImage = withContentRect('bounds')(props => {
  const { measureRef, contentRect, image, mobileImage, alignment } = props

  if (!image) {
    return null
  }

  const isMobileImage = mobileImage && shouldShowPortrait(contentRect.bounds)

  return (
    <div className="pageIntro-image" ref={measureRef}>
      <ARYImage
        key={isMobileImage ? 'mobile' : 'desktop'}
        imageAlignment={alignment}
        image={isMobileImage ? mobileImage : image}
      />
    </div>
  )
})

class PageIntro extends React.Component {
  constructor(props) {
    super(props)

    this.clickHandler = this.clickHandler.bind(this)
  }

  clickHandler(e) {
    const { onClick } = this.props

    e.preventDefault()

    if (onClick && isFunction(onClick)) {
      onClick()
    }
  }

  render() {
    const {
      title,
      mobileImage,
      image,
      alignment,
      colour,
      screenSize,
    } = this.props

    return (
      <div
        className={classnames('pageIntro', {
          [`bg--${colour}`]: colour,
          'txt--white': bgColourIsDark(colour),
        })}
        onTouchStart={this.clickHandler}
        onClick={this.clickHandler}
        style={{
          width: screenSize.width,
          height: screenSize.height,
        }}
      >
        <div className="pageIntro-text">
          <div className="cont">
            <div className="sectionSpacer sectionSpacer--double" />
            <ScrollTextEl title={title} titleEl="h1" />
            <div className="sectionSpacer sectionSpacer--double" />
          </div>
        </div>
        {image ? (
          <PageIntroImage
            alignment={alignment}
            image={image}
            mobileImage={mobileImage}
          />
        ) : null}
      </div>
    )
  }
}

const mapStateToProps = state => {
  const { ui } = state

  return {
    screenSize: ui.screenSize,
  }
}

export default connect(
  mapStateToProps,
  null
)(PageIntro)
